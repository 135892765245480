<template>
    <div>
        <main-header></main-header>
        <breadcrumbs></breadcrumbs>

        <div class="wrapper">

            <div class="content privacy">
                <div class="content__heading">
                    <h1>Terrific.ie Privacy & Cookies</h1>
                    <small>Last Updated 11 September 2020</small>
                </div>
                <h2>Introduction</h2>
                <p>Your privacy is important to us. This privacy policy has been created to explain how and why we
                    collect and use information about our users, and how we manage your privacy protection. We recommend
                    that you read this statement in full, so that you understand how we will use your personal
                    data. </p>
                <h2>What personal data do we collect?</h2>
                <p>Terrific.ie collects personal data to operate our business and to provide you with the product and
                    services that we offer. You provide some of this data to us directly, for example when you open an
                    account with us, contact an advertiser on the site, or send a query to our support team. Other data
                    is collected when you interact with our products, by using cookies and other technologies.</p>
                <p>When using our site, you have choices about what data we collect, and when you are asked to provide
                    personal data you may decline. If you decline to provide data that is necessary to provide you with
                    a particular product or feature, you may not be able to use this product or feature.</p>
                <p>We collect personal data in the following ways:</p>
                <h2>1. Through information you provide to us.</h2>
                <p>When you register with us as a user, you provide us with some personal data such as your name and
                    email address, which we will store in order to create your account. We will also store information
                    relating to any further use of our service, when you contact another user of the site through our
                    platform, the contents of the emails you send to them and any other information that you may upload
                    to the Sites. Finally, we may also collect information from any correspondence that you have with
                    us.</p>
                <h2>2. Through your use of our services.</h2>
                <p>When you use our services, we collect information about what types of services you use and how you
                    use them. For example, which parts of our websites you visit, and what advertisements you view. The
                    information that we collect while you use our services can be grouped into the following
                    categories:</p>
                <h3>Information about your device and internet connection</h3>
                <p>Using technical logs and other tools, we register information about the device you use, such as the
                    device manufacturer, operating systems and browser version. We also collect information about the
                    connection to our services, such as IP address, network ID, cookies and unique ID files. This
                    information can help us to adapt the way content is displayed to you, based on your device and user
                    profile.</p>
                <h3>Information about how our services are used</h3>
                <p>We also register information about how you use our services. For example, which pages of the site you
                    visit, the searches that you have performed, the times and dates that you visited our site, how long
                    a page is displayed on your screen, how you navigate around the page, which websites you have
                    visited before visiting our site, what functions you have carried out on a page, and if you have
                    completed a transaction.</p>
                <h3>Cookies and other locally stored content</h3>
                <p>When you use our services, cookies and other data are stored locally on your device. These cookies
                    provide us with information that we can then read. You can find more information about cookies, how
                    and why we use them, and how to manage them in our cookie policy.</p>
                <h3>Information about user location</h3>
                <p>We register your geographical location based on your IP address and mobile network code.</p>
                <h3>Messages</h3>
                <p>We collect information about when you send or receive messages from other users of our site.</p>
                <h2>3. Through other sources.</h2>
                <p>We receive information from third parties where you use their services to log into our site, for
                    example, if you log in using Facebook or Twitter.</p>
                <h2>Legal Basis for Processing</h2>
                <p>We process your Personal Data in order to ensure the necessary performance of your contract with us.
                    In certain circumstances, we may also process your Personal Data for the purposes of our legitimate
                    interests, such as to inform you of changes to our services, to provide you with information about
                    other services we offer, or in order to comply with any legal obligations to which we are
                    subject.</p>
                <p>We may also rely upon your consent as a relevant legal basis for processing your Personal Data. In
                    the event you wish to withdraw your consent to the collection and processing of your Personal Data
                    in this manner, please contact us.</p>
                <h2>How We Use Your Personal Data</h2>
                <p>We use the data that we collect to provide you with the products and services that we offer. This
                    allows us to improve the products that we offer you and to personalise your experience on our site.
                    We may also use the data to communicate with you, to inform you about your account and to provide
                    you with security updates. Where you have consented for us to do so, we may also use your data to
                    contact you in relation to updates about our own products, products provided by third parties, and
                    to help to show you more relevant adverts on the site.</p>
                <p>Your data will be used by us to authenticate your account and to enable you to interact with other
                    users of our site.</p>
                <h3>Contacting advertisers</h3>
                <p>When you contact an advertiser though our site, we will use the personal data that you have provided
                    as part of this contact to connect you to the advertiser. The advertiser will receive a copy of any
                    data that you have entered as part of your message to them.</p>
                <h3>Communications</h3>
                <p>We will contact you via email and messages posted on our website or apps. These messages will
                    communicate about service related issues and security updates. Where you have consented for us to do
                    so, we will also contact you with promotional marketing materials from us and from our partners. You
                    can update your marketing preferences at any time within your account. Please note that it will not
                    be possible to opt out of receiving service messages from us. This includes messages relating to
                    security and legal notices. </p>
                <h3>Advertising</h3>
                <p>Banner advertising on our site is targeted to visitors of the site through a variety of ad networks
                    and exchanges. We also use this data to measure the performance of these adverts. We use the data
                    that you provide to us, through your cookie preferences, either separately or combined to achieve
                    this.</p>
                <h3>Research and Developing Our Services</h3>
                <p>We use data that we collect through your use of our site and through public feedback, to conduct
                    research and develop our services based on our findings. This allows us to provide you with a better
                    experience when using our site.</p>
                <h3>Customer Support</h3>
                <p>We use the data that you provide to us, including your communications, to investigate and respond to
                    service issues and complaints.</p>
                <h3>Insights</h3>
                <p>We use your data to generate statistics about our site that do not identify you. These statistics
                    provide us with information about how our site is used, the number of ad impressions served or
                    clicked on, and the demographic distribution of visitors to our site.</p>
                <h3>Security Monitoring and Investigations</h3>
                <p>We will use your data when necessary to investigate and identify possible fraudulent behaviour on the
                    site and possible attempts to harm other members or visitors to the site. We will also use your data
                    to investigate violations of our Terms of Use or this Privacy Policy.</p>
                <h2>How we Share Your Information</h2>
                <p>We retain access to all personal information that we have collected from you. If you have consented
                    on signup or on the appropriate email form, we may provide any of the information we collect to
                    carefully screened third parties to contact you regarding products or services that we think may be
                    of interest to you. If Terrific.ie enters into a joint venture with or is sold to or merged with
                    another business entity, your information may be disclosed to our new business partners or
                    owners.</p>
                <h3>Our Services</h3>
                <p>Our service allows you to engage with other members of our site. If you send a message to another
                    member of our site, your message will be shared with that site member. If you comment on an advert,
                    or leave feedback on the account of another person, these will be publicly visible on our site.</p>
                <h3>Service Providers</h3>
                <p>We engage other service providers to help us to provide our service to you. For example, to process
                    payments, site maintenance and fraud detection. These service providers will have access to your
                    information as much as is reasonably necessary for them to perform these tasks on our behalf. They
                    are obligated not to disclose or use your data for any other purpose.</p>
                <h3>Legal Disclosures</h3>
                <p>When required to by law, we may disclose information about you. This may be as the result of a
                    subpoena or other legal process. Your personal information will also be shared if we believe that
                    the disclosure is necessary to</p>
                <ol>
                    <li>Be used by government enforcement agencies investigate, prevent or take action against any
                        suspected or actual illegal activities.
                    </li>
                    <li>To enforce the agreements that we have with you.</li>
                    <li>To investigate and defend Terrific.ie against any allegations made by third parties.</li>
                    <li>To protect the integrity and security of our services.</li>
                    <li>To exercise the rights and safety of Terrific.ie, our employees and other users of our
                        service.
                    </li>
                </ol>
                <h3>Change of Ownership</h3>
                <p>If Terrific.ie enters into a joint venture with or is sold to or merged with another business entity,
                    your information may be disclosed to our new business partners or owners in preparation for, or as a
                    result of, any of these events. Any other entity which buys Terrific.ie or any part of our business,
                    will have the right to continue to use your data in the way described by this privacy policy, unless
                    you consent otherwise.</p>
                <h2>Your Rights and Choices</h2>
                <p>The General Data Protection Regulations offer you a number of rights in relation to your data. This
                    includes the right to access a copy of your data, a right to receive a copy of your data that can be
                    transferred to another organisation, a right to data deletion, a right to withdraw your consent to
                    data processing, a right to restrict the processing of your data, and information on any automated
                    decision making processes.</p>
                <h3>Data Retention</h3>
                <p>The amount of time that we retain data for, depends on the type of data being retained, and how that
                    is used. In some cases, there will be a legal requirement for us to hold data for a specified amount
                    of time. In other cases, we will not retain the data for any longer than necessary for the purpose
                    that it was collected and processed.</p>
                <p>When you register an account with us, we will retain the personal information that you provide to us
                    for as long as you hold this account. Once an account has been closed, we will continue to retain
                    this personal data for a period of seven years. This timeframe is in line with the Irish statutory
                    limitation periods. If you don't actively close your account with us, it will automatically become
                    inactive after 3 months of you not accessing it. After this, the personal data you have provided us
                    will be retained for seven years. All other data provided, which is not personally identifiable,
                    will be retained for possible future use, including details outlined in the <a href="/terms">Terms &
                        Conditions.</a></p>
                <p>If you have provided data to us without registering an account, this data will be retained for 7
                    years after your last date of activity with us.</p>
                <h3>Right to Access Your Personal Data</h3>
                <p>Non- registered and registered users of the Sites can request we provide them with a copy of the
                    personally identifiable information we hold by using our data request feature.
                <p>
                <h3>Right to Control Your Personal Data</h3>
                <p>Registered users of our site can update their information by using our data request feature or
                    contacting our Customer Support Team directly. You can also edit and make changes to the personal
                    information by logging into your account and editing it directly from there. You can change your
                    marketing and contact preferences at any time in your account dashboard by opting out of receiving
                    these communications. You may ask us to make any necessary changes to ensure that such information
                    is accurate and kept up-to-date.</p>
                <h3>Right to Data Deletion</h3>
                <p>Non- registered and registered users of our sites can request we delete any personally identifiable
                    information we hold by using our data request feature. In some instances we may be unable to delete
                    your data if we are required to retain it to comply with our legal obligations (including requests
                    from law enforcement agencies).</p>
                <h3>Right to Data Portability</h3>
                <p>Registered users of our site have the right to data portability. This means that you can request that
                    a copy of your data is made available in a structured, commonly used and machine readable form that
                    can be transferred to you or to another organisation. You may request a portable copy of your data
                    by using our data request feature.</p>
                <h2>Other Important Information</h2>
                <h3>Special Category Personal Data</h3>
                <p>Terrific.ie does not collect any special categories of personal data. However, if you enter this
                    information into any free text section of our site, such as the ad description, or on our forums,
                    this information will be held and processed in the same manner as all other data that you have
                    provided to us.</p>
                <h3>Security</h3>
                <p>The security of your data is important to us. We perform regular monitoring of our systems to
                    identify possible vulnerabilities and attacks. However, we cannot warrant the security of any
                    information that you send to us. No guarantee is given that the data will not be accessed,
                    disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial
                    safeguards.</p>
                <h3>Direct marketing</h3>
                <p>We will only send you marketing information where you have opted in to receive these communications.
                    You can change your marketing preferences at any time, by logging into your account and changing
                    this on your account dashboard. You can also opt out of receiving marketing at any time using the
                    unsubscribe link that can be found at the end of each email that we send.</p>
                <h3>Changes to this Statement</h3>
                <p>This statement will be updated when necessary to reflect changes that we make to our products. When
                    this statement is updated, the "last updated" date will be revised at the top of this statement. We
                    encourage you to review our Privacy Policy regularly so that you can stay informed about our use of
                    your data.</p>
                <h3>Contact information</h3>
                <p>If you have any questions in relation to this Policy, please contact our Data Privacy Officer at
                    dpo<span class="spamprotection"></span>@ter<span class="spamprotection"></span>rific<span class="spamprotection"></span>.ie</p>
                <p>If contacting us does not resolve your complaint, you have the right to contact your local Data
                    Protection Commissioner. In Ireland, they can be contacted via their website <a
                        href="https://www.dataprotection.ie/" target="_blank">here.</a></p>

                <h1>Cookie Policy</h1>
                <h2>What are cookies and how are they used?</h2>
                <p>A cookie is a small amount of data, which often includes a unique identifier, that is sent to your
                    device's web browser from a website's computer and is stored on your device's hard drive. Each
                    website can send its own cookies to your web browser. Your browser will have the option to prevent
                    websites using cookies (your browser's help screen will tell you how to do this), but please note
                    that this may reduce the functionality of our website and other websites. You can also change your
                    targeted advertising cookie settings by visiting Google's Ad Personalisation and/or Your Online
                    Choices.</p>
                <p>Terrific.ie (the website) uses cookies to enable us to personalise your visits to our website,
                    simplify the sign-in processes, remember your preferences, track the website's performance, deliver
                    targeted advertising relevant to your interests and give you a better overall experience.</p>
                <h2>What types of cookies we use:</h2>
                <h2>Essential Cookies</h2>
                <p>These cookies are necessary for the website to function and cannot be switched off in our systems.
                    They are usually only set in response to actions made by you which amount to a request for services,
                    such as setting your privacy preferences, logging in or filling in forms.</p>
                <p>You can set your browser to block or alert you about these cookies, but some parts of the site will
                    then not work. These cookies do not store any personally identifiable information.</p>
                <h2>Functional Cookies</h2>
                <p>These cookies allow us to count visits and traffic sources so we can measure and improve the
                    performance of our site. They help us to know which pages are the most and least popular and see how
                    visitors move around the site. These cookies do not store any personally identifiable
                    information.</p>
                <h2>Analytics Cookies</h2>
                <p>These cookies allow us to count visits and traffic sources so we can measure and improve the
                    performance of our site. They help us to know which pages are the most and least popular and see how
                    visitors move around the site. These cookies do not store any personally identifiable
                    information.</p>
                <p>You can find more information about the individual cookies we use and the purposes for which we use
                    them below:</p>
                <h2>Our Cookies</h2>
                <ul>
                    <li>_ga - Performance - This cookie name is associated with Google Universal Analytics - which is a significant update to Google's more commonly used analytics service. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports. By default it is set to expire after 2 years, although this is customisable by website owners.</li>
                    <li>_gid - Performance - This cookie name is associated with Google Universal Analytics. This appears to be a new cookie and as of Spring 2017 no information is available from Google. It appears to store and update a unique value for each page visited.</li>
                    <li>__cfduid - Strictly Necessary - Cookie associated with sites using CloudFlare, used to speed up page load times. According to CloudFlare it is used to override any security restrictions based on the IP address the visitor is coming from. It does not contain any user identification information.</li>
                    <li>_gat_UA-129697119-1 - Targeting/Advertising - This is a pattern type cookie set by Google Analytics, where the pattern element on the name contains the unique identity number of the account or website it relates to. It appears to be a variation of the _gat cookie which is used to limit the amount of data recorded by Google on high traffic volume websites.</li>
                </ul>

                <h2>Targeting Cookies</h2>
                <p>These cookies may be set through our site by our advertising partners, e.g. DoubleClick by Google.
                    They may be used to build a profile of your interests and show you relevant adverts on other
                    sites.</p>
                <p>If you do not allow these cookies, you will still see the same number of advertisements, however they
                    may be less relevant to you. These cookies will be on by default but can managed directly by
                    visiting <a href="https://adssettings.google.com/" target="_blank">Google's Ads Personalisation</a>
                    and/or <a href="https://www.youronlinechoices.com/ie/" target="_blank">Your Online Choices.</a></p>

                <h2>Third Party Cookies</h2>
                <p>These cookies are set by entities other than the owner of the website you're visiting.</p>
                <p>Some Terrific.ie web pages may contain content from other sites (e.g. playable files from a video
                    sharing website), which may set their own cookies. Also, if you share a link to a Terrific.ie page,
                    the service you share it on (e.g. social networks) may set a cookie on your browser.</p>
                <p>Terrific.ie has no control over third party cookies.</p>
                <p>You can find more information about the third party cookies on Terrific.ie below:</p>
                <h2>Third Party Cookies</h2>
                <ul>
                    <li>__Secure-3PSIDCC - Targeting/Advertising - These cookies are used to deliver ads more relevant to you and your interests.</li>
                    <li>__Secure-3PAPISID - Targeting/Advertising - These cookies are used to deliver ads more relevant to you and your interests.</li>
                    <li>__Secure-3PSID - Targeting/Advertising - These cookies are used to deliver ads more relevant to you and your interests.</li>
                    <li>1P_JAR - Targeting/Advertising - This cookie carries out information about how the end user uses the website and any advertising that the end user may have seen before visiting the said website.</li>
                    <li>ANID - Advertising - The "ANID" cookie is used for advertising served across the web and stored in google.com.</li>
                    <li>APISID - Targeting/Advertising - This DoubleClick cookie is generally set through the site by advertising partners, and used by them to build a profile of the website visitor's interests and show relevant ads on other sites. This cookie works by uniquely identifying your browser and device.</li>
                    <li>CONSENT - Google Cookie -  Cookie	This cookie is used to track consent to cookie use.</li>
                    <li>HSID - Targeting/Advertising - This cookie is set by DoubleClick (which is owned by Google) to build a profile of the website visitor's interests and show relevant ads on other sites.</li>
                    <li>NID - These cookies are used by Google to display personalized advertisements on Google sites, based on recent searches and previous interactions.</li>
                    <li>OGPC - Functionality - This cookie enables the functionality of Google Maps.</li>
                    <li>OTZ - Performance - is a cookie used by Google Analytics that provides an aggregate analysis of Website visitors.</li>
                    <li>PREF - Targeting/Advertising - This cookie, which may be set by Google or Doubleclick, may be used by advertising partners to build a profile of interests to show relevant ads on other sites. It works by uniquely identifying your browser and device.</li>
                    <li>SAPISID - Targeting/Advertising - This DoubleClick cookie is generally set through the site by advertising partners, and used by them to build a profile of the website visitor's interests and show relevant ads on other sites. This cookie works by uniquely identifying your browser and device.</li>
                    <li>SID -  Strictly Necessary - This is a very common cookie name but where it is found as a session cookie it is likely to be used as for session state management.</li>
                    <li>SIDCC - Targeting/Advertising - This cookie carries out information about how the end user uses the website and any advertising that the end user may have seen before visiting the said website.</li>
                    <li>SSID - Targeting/Advertising - This cookie carries out information about how the end user uses the website and any advertising that the end user may have seen before visiting the said website.</li>
                    <li>LOGIN_INFO - Targeting/Advertising - Used by YouTube (Google) for storing user preferences and other unspecified purposes.</li>
                    <li>SEARCH_SAMESITE - Google Cookie - This cookie is used to prevent the browser from sending this cookie along with cross-site requests.</li>
                    <li>UULE - This cookie is used to collect information about how visitors use our Site. We use the information to compile reports and to help us improve the Site. The cookies collect information in an anonymous form, including the number of visitors to the Site, where visitors have come to the Site from and the pages they visited. If you do not allow these cookies we will not be able to include your visit in our statistics.</li>
                    <li>uvc - Targeting/Advertising - Tracks how often a user interacts with AddThis</li>
                    <li>VISITOR_INFO1_LIVE - Targeting/Advertising - This cookie is set by Youtube to keep track of user preferences for Youtube videos embedded in sites;it can also determine whether the website visitor is using the new or old version of the Youtube interface.</li>
                    <li>YSC - Performance - This cookie is set by YouTube to track views of embedded videos.</li>

                </ul>
                <h2>Your Choices</h2>
                <p>For more information on how to manage your cookie settings in respect of third party cookies please
                    go to <a href="www.allaboutcookies.org" target="_blank">www.allaboutcookies.org.</a> If you are
                    primarily concerned about third party cookies generated by advertisers, you can turn these off by
                    going to <a href="http://www.youronlinechoices.com"
                                target="_blank">http://www.youronlinechoices.com</a></p>
                <p>If, at any stage, we make amendments to our cookie policy, you will be notified and may be required
                    to update your choices as a result.</p>
            </div>

        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from '@/components/partials/Breadcrumbs'

    export default {
        name: "Privacy",
        components: {
            MainHeader,
            MainFooter,
            Breadcrumbs
        },
        data: function () {
            return {
                title: "Privacy - Used Cars, Cars in Ireland, Electric Cars, 7 Seaters, SUV’s | Terrific.ie",
                description: "Terrific.ie details used cars for sale in Ireland including electric cars, 7 seaters and SUV’s.",
                keywords: "Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover",
                robots: "all"
            }
        },
        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
        },
    }
</script>

<style scoped>



</style>
